import React from 'react';
import styled from 'styled-components';
import {Alert, Accordion} from 'react-bootstrap';



const StyledDay = styled.div`
border: 1px solid grey;
background-color: rgb(100, 100, 100);
margin: 2rem;
border-radius: 1rem;
padding: 1rem;
display: flex;
align-items: center;
font-size: 1.5rem;
flex-flow: column;
flex-grow: 1;
min-width: 300px;
max-width: 400px;
`
const DayBody = styled.div`

padding: 0rem;
font-size: 1rem;
width: 50%;
display: flex;
align-items: center;
flex-flow: column
`

const StyledAlert = styled(Alert)`
    font-size: 1.5rem;
    padding: 0.7rem;
    display: flex;
    justify-content: center;
    width: 90%
`
const StyledAccordion = styled(Accordion)`
    margin: 0.5rem;
    width: 100%
`
const AccordionHeader = styled(Accordion.Header)`

`
const AccordionBody = styled(Accordion.Body)`
display: flex;
flex-flow: column;
justify-content; center;
align-items: center;
padding: 0.5rem;
border: 2px solid grey;
border-bottom-left-radius: 1rem;
border-bottom-right-radius: 1rem;

`
class Day extends React.Component{
    constructor(props){
        super(props)
        this.state={

        }
    }
    render(){

        return(
        <StyledDay>
            <StyledAlert variant="primary">
                
                {this.props.title}
            </StyledAlert>
            <DayBody>

                {this.props.exercises.map((key, index)=>{
                    console.log(key)
                    return(<StyledAccordion key={index}>
                                <AccordionHeader>
                                    {key["Name"]}
                                </AccordionHeader>
                                <AccordionBody>
                                    <div>Sets: {key["Sets"]}</div>
                                    <div>Reps: {key["Reps"]}</div>
                                </AccordionBody>
                        </StyledAccordion>)
                })}
            </DayBody>
        </StyledDay>)
    }
}
export default Day;