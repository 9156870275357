import React from 'react'
import {Form, Button} from 'react-bootstrap'

import styled from 'styled-components'
import ExDropDown from './ExerciseDropDown'


const OuterDiv = styled.div`
display: flex;
width: 70%;
margin: 1rem;

`

const CustomAccoContainer = styled.div`
display: block;
width: 100%;
`
const FormControl = styled(Form.Control)`

`


const ShowMoreButton = styled(Button)`
display: flex;
justify-content: center;
align-items: center;
`


const StyledTrash = styled.div`
color: red;
display: flex;
align-items: center;
margin-right: auto;
`
class Exercise extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            exercise: this.props.exercise,
            show: true,
            expand: "expand_less"

        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSetChange =this.handleSetChange.bind(this)
        this.handleRepChange = this.handleRepChange.bind(this)
        
     



        this.deleteSelf = this.deleteSelf.bind(this)
        this.mouseEnterTrash = this.mouseEnterTrash.bind(this)
        this.mouseLeaveTrash = this.mouseLeaveTrash.bind(this)

        this.showStuff = this.showStuff.bind(this)


    }
    handleChange(event){
        this.setState({exercise: event.target.value})
        this.props.handleExerciseChange(event.target.value, this.props.exerciseNumber)
    }
    handleSetChange(value){

        this.props.handleSetChange(value, this.props.exerciseNumber)
    }
    handleRepChange(value){
        this.props.handleRepChange(value, this.props.exerciseNumber)

    }   
        
    deleteSelf(event){
        this.props.deleteExercise(this.props.exerciseNumber)
    }

    mouseEnterTrash(event){
        document.body.style.cursor = 'pointer'

    }
    mouseLeaveTrash(event){
        document.body.style.cursor = 'auto'

    } 
    showStuff(event){
        event.preventDefault();
        if (this.state.show == true){
            this.setState({show: false, expand: "expand_more"})
        } else {
            this.setState({show: true, expand: "expand_less"})
        }

    }

    render(){

        return(<OuterDiv  >
            <CustomAccoContainer>
                <div style = {{display: 'flex'}}>
                    <FormControl  ref = {this.myInputRef} onFocus={this.setInputFocus} autoFocus value={this.state.exercise} onChange = {this.handleChange}/>
                    <ShowMoreButton onClick = {this.showStuff}  variant = "light">
                        <div className="material-symbols-outlined">{this.state.expand}</div>
                    </ShowMoreButton>
                </div>
                <ExDropDown show={this.state.show} handleRepChange={this.handleRepChange} handleSetChange={this.handleSetChange}>


                </ExDropDown>
            </CustomAccoContainer>

            
            <StyledTrash className="material-symbols-outlined"onClick = {this.deleteSelf} onMouseEnter={this.mouseEnterTrash} onMouseLeave={this.mouseLeaveTrash} size = {40}icon="delete" color='#440000'>delete</StyledTrash>

            </OuterDiv>)
    }
}

export default Exercise