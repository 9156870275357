import React from 'react'
import styled from 'styled-components'
import {Form} from 'react-bootstrap'



const OuterDiv = styled.div`
display: flex;
background: rgba(200, 200, 200, 1);
border: 1px solid rgb(200, 200, 200);
justify-content: center;


border-radius: 0.5rem;
`


const FormGroup = styled(Form.Group)`
margin: 1rem;
margin-top: 0.5rem;
display: flex;
flex-flow: column;
align-items: center;
font-size: 0.75rem;

`
const SetRep = styled(Form.Control)`
text-align: center;
font-size: 0.75rem;

`
const FormLabel = styled(Form.Label)`
margin: 0

`

const MiddleX = styled.div`

display: flex;
align-items: center`
class ExDropDown extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            sets: '3',
            reps: '8'
        }
    this.handleSetChange = this.handleSetChange.bind(this)
    this.handleRepChange = this.handleRepChange.bind(this)
    }
    handleSetChange(event){
        this.setState({sets: event.target.value })
        this.props.handleSetChange(event.target.value)
    }
    handleRepChange(event){
        this.setState({reps: event.target.value })
        this.props.handleRepChange(event.target.value)
    }
    render(){
        if (this.props.show == true){
            return(

                <OuterDiv>
                        <FormGroup className="mb-3" controlId="exampleForm.ControlInput1">
                            <FormLabel>Sets</FormLabel>
                            <SetRep value={this.state.sets} onChange = {this.handleSetChange}/>
                        </FormGroup> 
                        <MiddleX>
                            <strong>
                            X
                            </strong> 
                        </MiddleX>
                        <FormGroup className="mb-3" controlId="exampleForm.ControlInput1">
                            <FormLabel>Reps</FormLabel>
                            <SetRep value={this.state.reps} onChange = {this.handleRepChange}/>
                        </FormGroup>       
                </OuterDiv>
            )                
        }    else {
            return (<div></div>)
        }     
       
    }
}


export default ExDropDown;